import Vue from 'vue';
import Router from 'vue-router';

import NotFound from './views/not-found.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'default',
      redirect: '/login'
    },

    {
      path: '/login',
      name: 'login',
      component: () =>
        import('./views/login.vue')
    },
    
    {
      path: '/main',
      name: 'main',
      component: () => import('./views/main.vue'),

      children: [


        { path: '/main/camiones_paneles', component: () => import('./views/camiones_paneles.vue')  },

        { path: '/main/camion_graficos', component: () => import('./views/camion_graficos.vue') },

        { path: '/main/camion_eblas', component: () => import('./views/camion_eblas.vue') },


        { path: '/main/usuarios', component: () => import('./views/usuarios.vue') },
        { path: '/main/configuracion', component: () => import('./views/configuracion.vue') },
        //{ path: '/main/configuracion', component: () => import('./views/configuracion.vue') },
        { path: '/main/reportes', component: () => import('./views/reportes.vue') },
      ]
    },

    //{
    //  path: '/purchased',
    //  name: 'purchased',
      // route level code-splitting
      // this generates a separate chunk (purchased.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
    //  component: () =>
     //   import(/* webpackChunkName: "purchased" */ './views/purchased.vue')
    //},
  
    { path: '*', component: NotFound }
  ]
});
