//
//
//
//
//
//

export default {
  name: "App",
  data: function () {
    return {
      in_login:'',
    }
  },

  created() {
     console.log('create -> [App]');
     console.log(this.$store.state.my_user);
  },
  metaInfo() {
    return {
      title: "",
      meta: [{
         vmid: "description",
          name: "description",
          content: "hello world, this is an example of adding a description with vueMeta"
      }]
    }
  }


};
