import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import router from './router';
import store from './store';
import axios from "axios";

import {util} from './util.js'


import moment from 'moment';

import Meta from "vue-meta";

//import axios from "axios";
//import ts_confirm from "@/components/ts_confirm";

Vue.use(Meta);

new Vue({
  vuetify,
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')



Vue.prototype.$bus  = new Vue ();
Vue.prototype.$util = util;
Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;


Vue.component('ts-confirm', () => import('@/components/ts_confirm'));


var script = document.createElement('script');
script.src = "https://cdn.plot.ly/plotly-2.4.2.min.js";
document.head.appendChild(script);


var meta = document.createElement('meta');
meta.httpEquiv = "Content-Security-Policy";
meta.content = "upgrade-insecure-requests";
document.getElementsByTagName('head')[0].appendChild(meta);


Vue.prototype.$GLOBAL = {
  ENV: 'PROD'
};







    /*
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json; charset=utf-8',
          "Access-Control-Allow-Origin": "*",
      }
    };
    */

  //method: 'POST',
  //headers: { 'content-type': 'application/x-www-form-urlencoded' },

    /*
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data:{user: 'admin', password: 'admin'},
      url:'/api/login/',
    };
    */
