
export const util = {
    //var self = this;


    isNull: (v) => {
        if (v === undefined || v === null) {
            return true;
        }
        else {
            return false;
        }
    },

    isNotNull: (v) => {
        return ! util.isNull(v);
    },
    

    log: (data) => {
      console.log(data);
    },

    clone: (obj) => {
        if ( obj === null || typeof obj  !== 'object' ) {
            return obj;
        }
        var temp = obj.constructor();
        for ( var key in obj ) {
            temp[ key ] = util.clone( obj[ key ] );
        }
        return temp;
    }

    
}